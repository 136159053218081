import { FC, lazy, Suspense, useState } from "react";
import { useIntl } from "react-intl";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { PublicRoutes, Routes as PrivateRoutes, UserRoles } from "../utils/appEnums/AppEnums";
import SuspenseSpinner from "../components/Common/SuspenseSpinner/SuspenseSpinner";

// const PrivateRoute = lazy(() => import("./PrivateRoute"));
const HomePageWrapper = lazy(() => import("../views/privatePages/HomePageWrapper/HomePageWrapper"));
const TalkingHeadDemo = lazy(() => import("../views/privatePages/TalkingHeadDemo/TalkingHeadDemo"));
const FormFillerAssistant = lazy(() => import("../views/privatePages/HomePageWrapper/components/HomePage/components/FormFillerAssistant/FormFillerAssistant"));
const PrivateRouteNotEmailVerified = lazy(() => import("./PrivateRouteNotEmailVerified"));
const Welcome = lazy(() => import("../views/privatePages/WelcomePage/Welcome"));
const PublicRoute = lazy(() => import("./PublicRoute"));
const Login = lazy(() => import("../views/publicPages/Login/Login"));
const Register = lazy(() => import("../views/publicPages/Register/Register"));
const ContactPointVerificationResult = lazy(
    () => import("../views/publicPages/VerificationPages/ContactPointVerificationResult/ContactPointVerificationResult"),
);
const ForgotPass = lazy(() => import("../views/publicPages/ForgotPassword/ForgotPass"));
const NotFound = lazy(() => import("../views/staticPages/NotFound/NotFound"));
const Authorized = lazy(() => import("../views/publicPages/Authorized/Authorized"));
const InitAuth = lazy(() => import("../views/publicPages/Login/InitAuth"));
const UpdatePassword = lazy(() => import("../views/publicPages/UpdatePassword/UpdatePassword"));
const EmailVerificationPage = lazy(() => import("../views/publicPages/VerificationPages/EmailVerificationPage/EmailVerificationPage"));
const AuthorizedErrorPage = lazy(() => import("../views/publicPages/Authorized/AuthorizedErrorPage"));

const exampleSpinner = <SuspenseSpinner />;

export const AppRoute: FC = () => {
    const { formatMessage } = useIntl();

    const setForceUpdate = useState<boolean>(false)[1];

    const updateAuthState = () => setForceUpdate((o) => !o);

    const routes = createBrowserRouter(
        createRoutesFromElements(
            <Route path={PrivateRoutes.BASE_PATH}>
                <Route
                    index
                    element={
                        <PublicRoute activeTabName={formatMessage({ id: "approute.tab.label-home", defaultMessage: "Home" })} restricted={false}>
                            <Suspense fallback={exampleSpinner}>
                                <TalkingHeadDemo />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.FORM_FILLER}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-form-filler", defaultMessage: "Form Filler Demo" })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <FormFillerAssistant />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.PLAYGROUND}
                    element={
                        <PublicRoute activeTabName={formatMessage({ id: "approute.tab.label-login", defaultMessage: "Playground" })} restricted>
                            <Suspense fallback={exampleSpinner}>
                                <HomePageWrapper />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.LOGIN}
                    element={
                        <PublicRoute activeTabName={formatMessage({ id: "approute.tab.label-login", defaultMessage: "Login" })} restricted>
                            <Suspense fallback={exampleSpinner}>
                                <Login />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.INIT_AUTH}
                    element={
                        <PublicRoute activeTabName={formatMessage({ id: "approute.tab.label-init", defaultMessage: "Initialize Auth Process" })} restricted>
                            <Suspense fallback={exampleSpinner}>
                                <InitAuth />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                {/* AUTHORIZATION */}
                <Route
                    path={PublicRoutes.AUTHORIZED}
                    element={
                        <PublicRoute activeTabName={formatMessage({ id: "approute.tab.label-authorized", defaultMessage: "Authorized" })} restricted>
                            <Suspense fallback={exampleSpinner}>
                                <Authorized updateAuthState={updateAuthState} />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.ERROR_AUTHORIZED}
                    element={
                        <Suspense fallback={exampleSpinner}>
                            <AuthorizedErrorPage />
                        </Suspense>
                    }
                />

                <Route
                    path={PublicRoutes.REGISTER_USER}
                    element={
                        <PublicRoute activeTabName={formatMessage({ id: "approute.tab.label-register-user", defaultMessage: "Register User" })} restricted>
                            <Suspense fallback={exampleSpinner}>
                                <Register />
                            </Suspense>
                        </PublicRoute>
                    }
                />

                <Route
                    path={PublicRoutes.CONTACT_POINT_VERIFICATION}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-contact-point-verification",
                                defaultMessage: "Contact Point Verification Result",
                            })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <ContactPointVerificationResult />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.VERIFY_EMAIL}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-email-verification", defaultMessage: "Email verification" })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <EmailVerificationPage />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.FORGOT_PASSWORD}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-forgot-password", defaultMessage: "Forgot Password" })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <ForgotPass />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={`${PublicRoutes.UPDATE_PASSWORD}/:userId/:code`}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-set-password", defaultMessage: "Set Password" })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <UpdatePassword />
                            </Suspense>
                        </PublicRoute>
                    }
                />

                <Route
                    path={PrivateRoutes.WELCOME}
                    element={
                        <PrivateRouteNotEmailVerified roles={[UserRoles.Staff]}>
                            <Suspense fallback={exampleSpinner}>
                                <Welcome />
                            </Suspense>
                        </PrivateRouteNotEmailVerified>
                    }
                />

                <Route path="*" element={<NotFound />} />
            </Route>,
        ),
    );

    // debug
    // routes.subscribe((state) => console.log("new state", state, JSON.stringify(state.location)));

    return <RouterProvider fallbackElement={exampleSpinner} router={routes} future={{ v7_startTransition: true }} />;
};

export default AppRoute;
