import { FC, useEffect, useState } from "react";
import Axios from "axios";
import { IntlProvider } from "react-intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import App from "../App";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import { BUILD_NUMBER } from "../config/app.config";
import useAbortApi from "../Hooks/useAbortApi";
import { isCancelledErrorProcessor } from "../utils/AppUtils";

const IntlAppLayout: FC = () => {
    const [pending, setPending] = useState(true);
    const [messages, setMessages] = useState<{ [x: string]: any }>({});
    const userLang = useSelector((store: any) => store.userReducer.lang);

    const { signal } = useAbortApi();

    useEffect(() => {
        const langFiles = ["en.json"];
        const promise: any[] = langFiles.map((file: any) => Axios.get(`/lang/${file}?v=${BUILD_NUMBER}`, { signal }));
        Axios.all(promise)
            .then((responses) => {
                const files: any = {};
                responses.forEach((item: any, index: number) => {
                    files[langFiles[index].split(/[.]/)[0]] = item.data;
                });

                setMessages(files);
                setPending(false);
            })
            .catch(isCancelledErrorProcessor)
            // eslint-disable-next-line no-console
            .catch((e) => console.warn(e));
    }, [signal]);

    if (pending) {
        return null;
    }

    return (
        <IntlProvider key={userLang} locale={userLang} messages={messages[userLang]}>
            <ErrorBoundary>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <App />
                </LocalizationProvider>
            </ErrorBoundary>
        </IntlProvider>
    );
};

export default IntlAppLayout;
