import { createTheme as createMuiTheme } from "@mui/material/styles";
import { BO_CORE_DEFAULT_VALUE } from "./AppUtils";

const defaultPalette = {
    type: "white",
    primary: {
        main: "#2D7ABE",
        secondary: "#44ADB3",
        contrastText: "#fff",
        softBlack: "#464B52",
    },
    secondary: {
        main: "#F9CF56",
        contrastText: "#000",
    },
    success: {
        main: "#1B8500",
        contrastText: "#fff",
    },
    error: {
        main: "#C33F57",
        contrastText: "#fff",
    },
    text: {
        primary: "#009CA6", // ! the same
        secondary: "#009CA6", // ! the same
    },
    background: {
        paper: "#fff", // # the same
        default: "#fff", // # the same
    },
    action: {
        disabled: "rgba(77,77,77,0.9)",
        disabledBackground: "rgba(152,152,152,0.64)",
        active: "rgb(151, 209, 213)",
    },
    teal: {
        main: "#47A2A8",
        hover: "#44ADB3",
        hoverOnDark: "#97D1D5",
        light: "#E2F3F4",
    },
    merigold: {
        main: "#FBC84A",
        hover: "#FFDD8A",
        light: "#FEF5D5",
        contrastText: "#464B52",
    },
    softBlack: {
        main: "#464B52",
        hover: "#5A6069",
    },
    mediumDarkGrey: {
        main: "#707680",
    },
    silverGrey: {
        main: "#8C98A8",
        light: "#DFE4EB",
    },
    silver: {
        main: "#D2D7E0",
    },
    softSilver: {
        main: "#F3F3F3",
        light: "#F8F8F8",
    },
    warningRed: {
        main: "#F1511B",
        text: "#EE6C4D",
        light: "#FFEBE4",
    },
    approvedGreen: {
        main: "#34A853",
        text: "#4BB567",
        light: "#74B93E",
    },
    cobaltBlue: {
        main: "#2D7ABE",
        hover: "#4D8EC8",
        light: "#E4F0F8",
    },
};

export const defaultThemeConfig = {
    palette: defaultPalette,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1280,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: "'Inter', sans-serif",
        allVariants: {
            color: defaultPalette.primary.softBlack,
        },
    },
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "rgb(151, 209, 213) !important",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: `5px`,
                    fontSize: "1rem",
                },
            },
            variants: [
                {
                    props: { variant: "CTA" } as const,
                    style: {
                        padding: "0.375rem 1rem",
                        backgroundColor: defaultPalette.merigold.main,
                        color: defaultPalette.primary.softBlack,
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: defaultPalette.merigold.hover,
                            color: defaultPalette.softBlack.hover,
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "CTA-TEAL" } as const,
                    style: {
                        padding: "0.375rem 1rem",
                        backgroundColor: defaultPalette.teal.hoverOnDark,
                        color: defaultPalette.primary.softBlack,
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: defaultPalette.teal.hover,
                            color: defaultPalette.softBlack.hover,
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "CTA-TEAL", disabled: true } as const,
                    style: {
                        padding: "0.375rem 1rem",
                        backgroundColor: defaultPalette.silver.main,
                        color: "#8C98A8 !important",
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: defaultPalette.silver.main,
                            color: "#8C98A8 !important",
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "CTA", size: "small" } as const,
                    style: {
                        padding: "0.5rem 1rem",
                        backgroundColor: defaultPalette.merigold.main,
                        color: defaultPalette.primary.softBlack,
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "2.06rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.21,
                        fontWeight: 600,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: defaultPalette.merigold.hover,
                            color: defaultPalette.softBlack.hover,
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "CTA", disabled: true } as const,
                    style: {
                        padding: "0.375rem 1rem",
                        backgroundColor: defaultPalette.silver.main,
                        color: "#8C98A8 !important",
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: defaultPalette.silver.main,
                            color: "#8C98A8 !important",
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "CTA", size: "small", disabled: true } as const,
                    style: {
                        padding: "0.5rem 1rem",
                        backgroundColor: defaultPalette.silver.main,
                        color: "#8C98A8 !important",
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "2.06rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.21,
                        fontWeight: 600,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: defaultPalette.silver.main,
                            color: "#8C98A8 !important",
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "CTAGhost" } as const,
                    style: {
                        padding: "0.375rem 1rem",
                        backgroundColor: "transparent",
                        color: defaultPalette.primary.softBlack,
                        borderRadius: "6.25rem",
                        border: "1px solid #FBC84A",
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: defaultPalette.merigold.light,
                            color: defaultPalette.primary.softBlack,
                            borderRadius: "6.25rem",
                            border: "1px solid #FBC84A",
                        },
                    },
                },
                {
                    props: { variant: "CTAGhost", size: "small" } as const,
                    style: {
                        padding: "0.4375rem 1rem",
                        backgroundColor: "transparent",
                        color: defaultPalette.primary.softBlack,
                        borderRadius: "6.25rem",
                        border: "1px solid #FBC84A",
                        minHeight: "2.06rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.21,
                        fontWeight: 600,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: defaultPalette.merigold.light,
                            color: defaultPalette.primary.softBlack,
                            borderRadius: "6.25rem",
                            border: "1px solid #FBC84A",
                        },
                    },
                },
                {
                    props: { variant: "CTAGhost", color: "primary" } as const,
                    style: {
                        padding: "0.375rem 1rem",
                        backgroundColor: "transparent",
                        color: defaultPalette.primary.softBlack,
                        borderRadius: "6.25rem",
                        border: "1px solid #47A2A8",
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: defaultPalette.teal.light,
                            color: defaultPalette.primary.softBlack,
                            borderRadius: "6.25rem",
                            border: "1px solid #47A2A8",
                        },
                    },
                },
                {
                    props: { variant: "CTAGhost", disabled: true } as const,
                    style: {
                        padding: "0.375rem 1rem",
                        backgroundColor: "transparent",
                        color: "#8C98A8 !important",
                        borderRadius: "6.25rem",
                        border: "1px solid #D2D7E0",
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&:hover": {
                            backgroundColor: "transparent !important",
                            color: "#8C98A8 !important",
                            borderRadius: "6.25rem",
                            border: "1px solid #D2D7E0",
                        },
                    },
                },
                {
                    props: { variant: "CTAWITHICONLINK" } as const,
                    style: {
                        padding: "0.375rem 0",
                        backgroundColor: "transparent",
                        color: defaultPalette.primary.main,
                        border: 0,
                        minWidth: "auto",
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "left" as "left",
                        justifyContent: "space-between",
                        "& .MuiButton-endIcon": {
                            width: "0.75rem",
                            height: "0.75rem",
                            marginLeft: "0.5rem",
                            color: "inherit",
                            "&>*:nth-of-type(1)": {
                                fontSize: "0.75rem",
                            },
                        },
                        "&:hover": {
                            color: defaultPalette.cobaltBlue.hover,
                            backgroundColor: "transparent",
                        },
                    },
                },
                {
                    props: { variant: "CTAIcon" } as const,
                    style: {
                        padding: "0.375rem 1.25rem",
                        backgroundColor: defaultPalette.merigold.main,
                        color: defaultPalette.primary.softBlack,
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&.MuiButton-endIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "&.MuiButton-startIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.merigold.hover,
                            color: defaultPalette.softBlack.hover,
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "CTAIcon", disabled: true } as const,
                    style: {
                        padding: "0.375rem 1.25rem",
                        backgroundColor: defaultPalette.silver.main,
                        color: "#8C98A8 !important",
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&.MuiButton-endIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "&.MuiButton-startIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.silver.main,
                            color: "#8C98A8 !important",
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "CTAIcon", color: "primary" } as const,
                    style: {
                        padding: "0.375rem 1.25rem",
                        backgroundColor: defaultPalette.teal.main,
                        color: defaultPalette.primary.contrastText,
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&.MuiButton-endIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "&.MuiButton-startIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.primary.secondary,
                            color: defaultPalette.primary.contrastText,
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "CTAIcon", color: "primary", disabled: true } as const,
                    style: {
                        padding: "0.375rem 1.25rem",
                        backgroundColor: defaultPalette.silver.main,
                        color: "#8C98A8 !important",
                        borderRadius: "6.25rem",
                        border: 0,
                        minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        textAlign: "center" as "center",
                        "&.MuiButton-endIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "&.MuiButton-startIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.silver.main,
                            color: "#8C98A8 !important",
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "TextButtonWithIcon" } as const,
                    style: {
                        padding: "0",
                        color: defaultPalette.primary.softBlack,
                        backgroundColor: "transparent",
                        border: 0,
                        // minHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.21,
                        fontWeight: 600,
                        textAlign: "center" as "center",
                        "& .MuiButton-startIcon": {
                            width: "1.25rem",
                            height: "1.25rem",
                            marginRight: "0.5rem",
                            color: "inherit",
                        },
                        "&:hover": {
                            color: defaultPalette.softBlack.hover,
                            backgroundColor: "transparent",
                        },
                    },
                },
                {
                    props: { variant: "TextButtonWithIcon", disabled: true } as const,
                    style: {
                        padding: "0",
                        color: defaultPalette.silverGrey.main,
                        border: 0,
                        fontSize: "0.875rem",
                        lineHeight: 1.21,
                        fontWeight: 600,
                        textAlign: "center" as "center",
                        "& .MuiButton-startIcon": {
                            width: "1.25rem",
                            height: "1.25rem",
                            marginRight: "0.5rem",
                            color: "inherit",
                        },
                    },
                },
                {
                    props: { variant: "ConfirmIconBtn" } as const,
                    style: {
                        padding: "0.375rem 0.95rem",
                        backgroundColor: defaultPalette.teal.light,
                        color: defaultPalette.softBlack.main,
                        border: 0,
                        maxHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.6,
                        fontWeight: 700,
                        width: "fitContent",
                        textAlign: "left" as const,
                        justifyContent: "space-between",
                        "& .MuiButton-endIcon": {
                            color: defaultPalette.teal.main,
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "& .MuiButton-startIcon": {
                            color: defaultPalette.teal.main,
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.teal.light,
                            color: defaultPalette.teal.main,
                        },
                    },
                },
                {
                    props: { variant: "ConfirmIconBtn", disabled: true } as const,
                    style: {
                        padding: "0.375rem 0.95rem",
                        backgroundColor: defaultPalette.silver.main,
                        color: defaultPalette.softBlack.main,
                        border: 0,
                        maxHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.6,
                        fontWeight: 700,
                        width: "fitContent",
                        textAlign: "left" as const,
                        justifyContent: "space-between",
                        "& .MuiButton-endIcon": {
                            color: defaultPalette.mediumDarkGrey.main,
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "& .MuiButton-startIcon": {
                            color: defaultPalette.warningRed.main,
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.silverGrey.light,
                            color: defaultPalette.softBlack.main,
                        },
                    },
                },
                {
                    props: { variant: "RejectIconBtn" } as const,
                    style: {
                        padding: "0.375rem 0.95rem",
                        backgroundColor: defaultPalette.warningRed.light,
                        color: defaultPalette.softBlack.main,
                        border: 0,
                        maxHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.6,
                        fontWeight: 700,
                        width: "fitContent",
                        textAlign: "left" as const,
                        justifyContent: "space-between",
                        "& .MuiButton-endIcon": {
                            color: defaultPalette.warningRed.main,
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "& .MuiButton-startIcon": {
                            color: defaultPalette.warningRed.main,
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.warningRed.light,
                            color: defaultPalette.warningRed.main,
                        },
                    },
                },
                {
                    props: { variant: "RejectIconBtn", disabled: true } as const,
                    style: {
                        padding: "0.375rem 0.95rem",
                        backgroundColor: defaultPalette.silver.main,
                        color: defaultPalette.softBlack.main,
                        border: 0,
                        maxHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.6,
                        fontWeight: 700,
                        width: "fitContent",
                        textAlign: "left" as const,
                        justifyContent: "space-between",
                        "& .MuiButton-endIcon": {
                            color: defaultPalette.mediumDarkGrey.main,
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "& .MuiButton-startIcon": {
                            color: defaultPalette.warningRed.main,
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.silverGrey.light,
                            color: defaultPalette.softBlack.main,
                        },
                    },
                },
                {
                    props: { variant: "PrimaryIcon" } as const,
                    style: {
                        padding: "0.375rem 1.25rem",
                        backgroundColor: defaultPalette.merigold.main,
                        color: defaultPalette.primary.softBlack,
                        borderRadius: "6.25rem",
                        border: 0,
                        maxHeight: "3rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.6,
                        fontWeight: 700,
                        width: "fitContent",
                        textAlign: "center" as "center",
                        "&.MuiButton-endIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "&.MuiButton-startIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.merigold.hover,
                            color: defaultPalette.softBlack.hover,
                            borderRadius: "6.25rem",
                        },
                    },
                },
                {
                    props: { variant: "PrimaryIcon", disabled: true } as const,
                    style: {
                        padding: "0.375rem 1.25rem",
                        backgroundColor: defaultPalette.silver.main,
                        color: defaultPalette.silverGrey.main,
                        borderRadius: "6.25rem",
                        border: 0,
                        maxHeight: "2rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.28,
                        fontWeight: 700,
                        width: "fitContent",
                        textAlign: "center" as "center",
                        "&.MuiButton-endIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginLeft: "0.5rem",
                        },
                        "&.MuiButton-startIcon": {
                            color: "inherit",
                            fontSize: "1.25rem",
                            marginRight: "0.5rem",
                        },
                        "&:hover": {
                            backgroundColor: defaultPalette.primary.secondary,
                            color: defaultPalette.primary.contrastText,
                            borderRadius: "6.25rem",
                        },
                    },
                },
            ],
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    borderRadius: `8px !important`,
                    color: `#000`,
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px !important",
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: 0,
                    marginTop: 9,
                    marginRight: 0,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: "0rem",
                    textAlign: "end" as "end",
                },
            },
        },
    },
    props: {
        MuiOutlinedInput: {
            disableUnderline: true,
        },
    },
    brand: "#E22525",
    neutral: "#CDCDCD",
    infoMissingText: defaultPalette.primary.softBlack,
    footerHeight: 72,
    mobileFooterHeight: 56,
};

const isColorValid = (strColor): boolean => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== "";
};

export const generateTheme = (themeConfig: any) => {
    const shallowThemeConfig = themeConfig;

    const themeKeysPalette = Object.keys(themeConfig.palette);
    themeKeysPalette.forEach((key) => {
        switch (key) {
            case "primary":
                if (!isColorValid(shallowThemeConfig.palette[key].main)) {
                    shallowThemeConfig.palette[key].main = "main" in defaultThemeConfig.palette.primary! ? defaultThemeConfig.palette.primary.main : undefined;
                }
                if (!isColorValid(shallowThemeConfig.palette[key].contrastText)) {
                    shallowThemeConfig.palette[key].contrastText =
                        "contrastText" in defaultThemeConfig.palette.primary! ? defaultThemeConfig.palette.primary.contrastText : undefined;
                }
                break;
            case "secondary":
                if (!isColorValid(shallowThemeConfig.palette[key].main)) {
                    shallowThemeConfig.palette[key].main =
                        "main" in defaultThemeConfig.palette.secondary! ? defaultThemeConfig.palette.secondary.main : undefined;
                }
                if (!isColorValid(shallowThemeConfig.palette[key].contrastText)) {
                    shallowThemeConfig.palette[key].contrastText =
                        "contrastText" in defaultThemeConfig.palette.secondary! ? defaultThemeConfig.palette.secondary.contrastText : undefined;
                }
                break;
            case "text":
                if (!isColorValid(shallowThemeConfig.palette[key].primary)) {
                    shallowThemeConfig.palette[key].primary = defaultThemeConfig.palette.text?.primary;
                }
                if (!isColorValid(shallowThemeConfig.palette[key].secondary)) {
                    shallowThemeConfig.palette[key].secondary = defaultThemeConfig.palette.text?.secondary;
                }
                break;
            case "background":
                if (!isColorValid(shallowThemeConfig.palette[key].paper)) {
                    shallowThemeConfig.palette[key].paper = defaultThemeConfig.palette.background?.paper;
                }
                if (!isColorValid(shallowThemeConfig.palette[key].default)) {
                    shallowThemeConfig.palette[key].default = defaultThemeConfig.palette.background?.default;
                }
                break;
            case "action":
                if (!isColorValid(shallowThemeConfig.palette[key].disabled)) {
                    shallowThemeConfig.palette[key].disabled = defaultThemeConfig.palette.action?.disabled;
                }
                if (!isColorValid(shallowThemeConfig.palette[key].disabledBackground)) {
                    shallowThemeConfig.palette[key].disabledBackground = defaultThemeConfig.palette.action?.disabledBackground;
                }
                if (!isColorValid(shallowThemeConfig.palette[key].active)) {
                    shallowThemeConfig.palette[key].active = defaultThemeConfig.palette.action?.active;
                }
                break;
            default:
                break;
        }
    });

    const themeKeysTypography = Object.keys(shallowThemeConfig.typography);
    themeKeysTypography.forEach((key) => {
        switch (key) {
            case "allVariants":
                if (!isColorValid(shallowThemeConfig.typography[key].color)) {
                    shallowThemeConfig.typography[key].color =
                        "allVariants" in defaultThemeConfig.typography! && "color" in defaultThemeConfig.typography.allVariants!
                            ? defaultThemeConfig.typography.allVariants.color
                            : undefined;
                }
                break;
            case "fontFamily":
                if (shallowThemeConfig.typography[key] === "" || shallowThemeConfig.typography[key] === BO_CORE_DEFAULT_VALUE) {
                    shallowThemeConfig.typography[key] = "fontFamily" in defaultThemeConfig.typography! ? defaultThemeConfig.typography.fontFamily : undefined;
                }
                break;
            default:
                break;
        }
    });

    const finalThemeConfig = {
        ...shallowThemeConfig,
        components: {
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: defaultPalette.text.primary,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: `5px !important`,
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        overflow: "hidden",
                        borderRadius: `8px !important`,
                        color: `#000`,
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        marginBottom: 0,
                        marginTop: 9,
                    },
                },
            },
            props: {
                MuiOutlinedInput: { disableUnderline: true },
            },
        },
    };

    return createMuiTheme(finalThemeConfig);
};
