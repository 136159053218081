interface IProgressSpinner {
    show: boolean;
    progress: number;
}

export interface SpinnerReducerState {
    showSpinner: boolean;
    progressSpinner: IProgressSpinner;
}

const initialState: SpinnerReducerState = {
    showSpinner: false,
    progressSpinner: {
        show: false,
        progress: 0,
    },
};

type args = { type?: string; [key: string]: any };

// eslint-disable-next-line default-param-last
const SpinnerReducer = (state: SpinnerReducerState = initialState, { type, ...rest }: args) => {
    switch (type) {
        case "set":
            return { ...state, ...rest };
        default:
            return state;
    }
};
export default SpinnerReducer;
