import * as Sentry from "@sentry/react";
import { FC, useEffect, useRef } from "react";
import { BUILD_DATE, BUILD_VERSION, SENTRY_DSN_KEY, SENTRY_SAMPLE_RATE } from "../config/app.config";

const SentryProfiler: FC<any> = ({ children }) => {
    const alreadyInitialized = useRef(false);

    useEffect(() => {
        if (alreadyInitialized.current) {
            return;
        }

        Sentry.init({
            dsn: SENTRY_DSN_KEY,
            integrations: [new Sentry.BrowserTracing()],
            tracesSampleRate: Number(SENTRY_SAMPLE_RATE),
            release: `${BUILD_VERSION}, date ${BUILD_DATE}`,
            ignoreErrors: ["ResizeObserver loop limit exceeded", "ResizeObserver loop completed with undelivered notifications."],
        });

        alreadyInitialized.current = true;
    }, []);

    return children;
};

export default Sentry.withProfiler(SentryProfiler);
