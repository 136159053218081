/* eslint-disable default-param-last */
/* eslint-disable  dot-notation */

import { APP_DEFAULT_LANGUAGE, LOCAL_STORAGE_ITEM_LANG, LOCAL_STORAGE_ITEM_LOCALE } from "../config/app.config";
import { LOCALE_DETAILS } from "../config/LocaleDetails";
import { getCurrentLocale, getLocales } from "../utils/DateTimeUtils";

export const actions = {
    FETCH_AUTH_USER: "FETCH_AUTH_USER",
    FETCH_AUTH_USER_FAILED: "FETCH_AUTH_USER_FAILED",
    AUTH_USER: "AUTH_USER",
    UNAUTH_USER: "UNAUTH_USER",
    SET_TENANT: "SET_TENANT",
    SET_TENANT_CONTEXT: "SET_TENANT_CONTEXT",
    //    // SET_UI_ROUTES: 'SET_UI_ROUTES',
    SET_APPLICATION_LOCALE: "SET_APPLICATION_LOCALE",
    // SET_APPLICATION_LANG: "SET_APPLICATION_LANG",
    ADD_NEW_TENANT: "ADD_NEW_TENANT",
    ADD_CREATE_API_KEY_DATA: "ADD_CREATE_API_KEY_DATA",
} as const;

export interface UserReducerState {
    user: any;
    tenant: any;
    locales: any;
    locale: any;
    lang: string;
    uiRoutes: String[];
    insuranceInfo?: any[];
    loading: boolean;
}

function getApplicationLocale(): { label: string; value: string } {
    function setLocaleToLocalStorage(): { label: string; value: string } {
        const localeTag = getCurrentLocale();
        const localeOption = {
            // label: AppUtils.getLocaleNameByTag(localeTag),
            label: LOCALE_DETAILS[localeTag] || localeTag,
            value: localeTag,
        };
        window.localStorage.setItem(LOCAL_STORAGE_ITEM_LOCALE, JSON.stringify(localeOption));
        return localeOption;
    }

    try {
        const locale: { label: string; value: string } = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_ITEM_LOCALE) as string);
        if (!locale) {
            return setLocaleToLocalStorage();
        }
        return locale;
    } catch (e) {
        return setLocaleToLocalStorage();
    }
}

function setLangToLocalStorage(lang: string): string {
    window.localStorage.setItem(LOCAL_STORAGE_ITEM_LANG, lang);
    return lang;
}

function getApplicationLang(): string {
    let lang = window.localStorage[LOCAL_STORAGE_ITEM_LANG];
    if (!lang) {
        // lang = navigator.language ? navigator.language.split("-")[0] : APP_DEFAULT_LANGUAGE;
        lang = APP_DEFAULT_LANGUAGE;
        setLangToLocalStorage(lang);
    }
    return lang;
}

const initialState: UserReducerState = {
    user: null,
    tenant: null,
    locales: getLocales(),
    locale: getApplicationLocale(),
    lang: getApplicationLang(),
    uiRoutes: [],
    loading: false,
};

const userReducer = (state: UserReducerState = initialState, action: any) => {
    const shallowState = { ...state };

    switch (action.type) {
        case actions.FETCH_AUTH_USER:
            shallowState.loading = true;
            return shallowState;
        case actions.AUTH_USER:
            shallowState.user = action.payload;
            shallowState.loading = false;
            return shallowState;
        case actions.FETCH_AUTH_USER_FAILED:
            shallowState.loading = false;
            return shallowState;
        case actions.UNAUTH_USER:
            shallowState.user = null;
            return shallowState;
        case actions.SET_TENANT:
            shallowState.tenant = action.payload;
            return shallowState;
        case actions.SET_TENANT_CONTEXT:
            shallowState.user.tenantContext = action.payload;
            return shallowState;
        // case actions.SET_UI_ROUTES:
        // state.uiRoutes = action.payload;
        // return state;
        case actions.SET_APPLICATION_LOCALE:
            shallowState.locale = action.payload;
            window.localStorage.setItem(LOCAL_STORAGE_ITEM_LOCALE, JSON.stringify(action.payload));
            return shallowState;
        // case actions.SET_APPLICATION_LANG:
        // state.lang = action.payload;
        // setLangToLocalStorage(action.payload);
        // return state;

        case actions.ADD_NEW_TENANT:
            shallowState.user.tenants.push(action.payload);
            return shallowState;
        default:
            return shallowState;
    }
};

export default userReducer;
