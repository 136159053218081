import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";

interface SuspenseSpinnerProps {}

const SuspenseSpinner: FunctionComponent<SuspenseSpinnerProps> = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "set", showSpinner: true });
        return () => {
            dispatch({ type: "set", showSpinner: false });
        };
    }, []);

    return null;
};

export default SuspenseSpinner;
