import { CustomFile } from "../utils/appInterfaces/AppInterfaces";

export const actions = {
    SET_LOCAL_IMAGE_FILES: "SET_LOCAL_IMAGE_FILES",
    REMOVE_LOCAL_IMAGE_FILES: "REMOVE_LOCAL_IMAGE_FILES",
    REMOVE_LOCAL_SINGLE_IMAGE_FILE: "REMOVE_LOCAL_SINGLE_IMAGE_FILE",
    REMOVE_LOCAL_IMAGE_BY_TYPE: "REMOVE_LOCAL_IMAGE_BY_TYPE",
} as const;

export interface LocalImage {
    id: any;
    imageFiles: CustomFile;
}

export interface LocalImageFiles {
    imageType: string;
    imageFiles: LocalImage[];
}

export interface LocalImageFilesState {
    localImageFiles: LocalImageFiles[];
}

const initialState: LocalImageFilesState = {
    localImageFiles: [],
};

// eslint-disable-next-line default-param-last
const LocalImageFilesReducer = (state: LocalImageFilesState = initialState, action: any) => {
    const newState = { ...state };
    switch (action.type) {
        case actions.SET_LOCAL_IMAGE_FILES:
            newState.localImageFiles = [...newState.localImageFiles, action.payload];
            return newState;
        case actions.REMOVE_LOCAL_IMAGE_BY_TYPE:
            newState.localImageFiles = newState.localImageFiles.filter((imageFiles) => imageFiles.imageType !== action.payload);
            return newState;
        case actions.REMOVE_LOCAL_SINGLE_IMAGE_FILE:
            newState.localImageFiles = newState.localImageFiles
                .filter((imageFiles) => imageFiles.imageType !== action.payload.type)
                .map((imageFiles) => ({
                    imageType: imageFiles.imageType,
                    imageFiles: imageFiles.imageFiles.filter((imageFile) => imageFile.id !== action.payload.id),
                }));
            return newState;
        case actions.REMOVE_LOCAL_IMAGE_FILES:
            newState.localImageFiles = [];
            return newState;
        default:
            return newState;
    }
};

export default LocalImageFilesReducer;
