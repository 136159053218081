/* eslint-disable camelcase */
import { AxiosError, AxiosInstance } from "axios";
import isEmpty from "lodash/isEmpty";
import { ReactNode } from "react";
import { PublicRoutes } from "./appEnums/AppEnums";
import { LOCALE_DETAILS } from "../config/LocaleDetails";
import { API_BASE_URL, NODE_ENV } from "../config/app.config";

// import { uploadFile, uploadFiles } from "../config/axios/ApiRequests";

interface AxiosInstances {
    V1: AxiosInstance;
    V2: AxiosInstance;
    V3: AxiosInstance;
    graphqlInstance: AxiosInstance;
    NOV: AxiosInstance;
    AUTH: AxiosInstance;
}

let axiosInstances: AxiosInstances;
let toast: any;

export const setAxiosInstances = (value: any) => {
    axiosInstances = value;
};

export const getAxiosInstances = () => {
    return axiosInstances;
};

export const setToast = (value: any) => {
    toast = value;
};

export const getToast = () => {
    return toast;
};

export const maxFileSize = 6291456; // 6mb

/* Application */
// static readonly APP_DEFAULT_LANGUAGE = "en";

// static readonly LOCAL_STORAGE_ITEM_LOCALE = "Locale";

export const DEFAULT_BOCORE_DATE = "1970-01-01T00:00:00";

// static readonly LOCAL_STORAGE_ITEM_LANG = "Language";

export const SUPPORTED_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/bmp"];

export const BO_CORE_DEFAULT_VALUE = "n/a";

export const DEFAULT_TEMPERATURE_VALUE = -1;

export const BO_CORE_DEFAULT_NUMBER_VALUE = -1;

export const BO_CORE_TEMPERATURE_UNIT_FAHRENHEIT = "F";

export const BO_CORE_TEMPERATURE_UNIT_CELSIUS = "C";

export const ENGLISH_LOCALE_KEY = "en";

export const DEFAULT_LOCALE_DATE_TIME_CURRENCY = "English - United States";

export const IMAGE_TYPE_DEFAULT = "default";

export const sidebarWidth = 214;

export const sidebarWidthCollapsed = 75;

export const headerHeight = 72;

export const fieldVariant = "outlined";

export const DEFAULT_DATE_VALUE = "1970-01-01T00:00:00";

export const NO_INFORMATION_PROVIDED = "N/A";

export const GENERIC_PRO_GRID_DEFAULT_PAGE_SIZE = 50;

export const CANCELLED_ERROR_CODE = "ERR_CANCELED";

export const MAIN_DOMAIN_PARTS_LENGTH = 3;

export function toastError(message: string | ReactNode) {
    if (getToast()) {
        getToast().error(message, { position: "top-right", autoClose: 5000 });
    }
}

export function success(message: string | ReactNode) {
    if (getToast()) {
        getToast().success(message, { position: "top-right", autoClose: 5000 });
    }
}

export function warning(message: string | ReactNode) {
    if (getToast()) {
        getToast().warning(message, { position: "top-right", autoClose: 5000 });
    }
}

export function info(message: string | ReactNode) {
    if (getToast()) {
        getToast().info(message, { position: "top-right", autoClose: 5000 });
    }
}

export function convertToF(celsius: number): number {
    return (celsius * 9) / 5 + 32;
}

export function convertToC(fahrenheit: number): number {
    return (fahrenheit - 32) / 1.8;
}
/* Toast */

export function isPathPublic(pathname: string) {
    const routes = Object.keys(PublicRoutes);
    const publicRoutes: string[] = routes.map((route) => {
        return `${PublicRoutes[route]}`;
    });

    return publicRoutes.includes(pathname);
}

export const getLocaleNameByTag = (localeTag: string) => {
    const label = LOCALE_DETAILS[localeTag];
    return label || localeTag;
};

export const getCappedNumber = (x: number, max: number, min: number, cap: number) => {
    const scale = max - min;
    const diff = scale * cap;
    const result = Math.round(((x - min) / scale) * 10000) / 100;
    const cappedMin = Math.round(((0 - diff) / scale) * 10000) / 100;
    const cappedMax = Math.round(((max - min + diff) / scale) * 10000) / 100;

    if (result < cappedMin) {
        return cappedMin;
    }

    if (result > cappedMax) {
        return cappedMax;
    }

    return result;
};

/**
 * @deprecated when used with useValueListOptions as useValueListOptions already sorts alphabetically
 */

export const dynamicSort = (propertyItem: string) => {
    let property = propertyItem;
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = BO_CORE_DEFAULT_NUMBER_VALUE;
        property = property.substr(1);
    }

    return (a, b) => {
        /* next line works with strings and numbers */
        const secondSortOption = a[property] > b[property] ? 1 : 0;

        const result = a[property] < b[property] ? BO_CORE_DEFAULT_NUMBER_VALUE : secondSortOption;

        return result * sortOrder;
    };
};

export const dynamicSortMultiple = (props: string[]) => {
    // not used
    return (obj1, obj2) => {
        let i = 0;
        let result = 0;
        const numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i += 1;
        }
        return result;
    };
};

export const toCamelCase = (str: string) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (leftTrim, index) => (index === 0 ? leftTrim.toLowerCase() : leftTrim.toUpperCase())).replace(/\s+/g, "");
};

export const capitalizeEachWord = (str: string) => {
    return str
        .split(/\s+/)
        .filter((e) => e.length > 0)
        .map((e) => `${e[0].toUpperCase()}${e.slice(1)}`)
        .join(" ");
};

export const convertToBooleanBoolStrings = (value: "true" | "false"): boolean => {
    if (value === "true") {
        return true;
    }
    return false;
};

export const isAllObjectPropsWithNoValue = (dataObject: Object) => {
    if (typeof dataObject !== "object" || isEmpty(dataObject)) {
        return true;
    }
    const hasObjPropValue = Object.keys(dataObject).some((key) => {
        if (typeof dataObject[key] === "object" && isEmpty(dataObject[key])) {
            return false;
        }
        return !!dataObject[key];
    });
    return !hasObjPropValue;
};

export const debounceFunc = (callback: Function, duration: number) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), duration);
    };
};

export const getFileExtension = (fileName: string) => {
    if (!fileName) {
        return fileName;
    }

    return fileName.substring(fileName.lastIndexOf(".") + 1);
};

export const copyToClipboard = (data: string | number) => {
    const text = data.toString();
    return navigator.clipboard.writeText(text);
};

export const isEmptyStringValue = (value: string): boolean => {
    return value.trim() === "";
};

export const isCancelledError = (e: AxiosError) => e.code === CANCELLED_ERROR_CODE;

export const isCancelledErrorProcessor = (e: AxiosError) => {
    if (e.code === CANCELLED_ERROR_CODE) {
        return;
    }

    throw e;
};

export const hasNoValue = (value: any) => {
    return value === null || value === BO_CORE_DEFAULT_VALUE || value === undefined || value === "-";
};

export const getApiHostname = (): string => {
    let { hostname } = window.location;

    if (hostname.includes("localhost") || hostname.includes("127.0.0.1") || hostname.includes("local-test.talking-head.io")) {
        if (!API_BASE_URL || API_BASE_URL === "/") {
            return "dev.talking-head.io";
        }

        const url = new URL(API_BASE_URL);
        hostname = url.hostname;
    }

    return hostname;
};

export const getHostnameWithoutTenantSubdomain = (): string => {
    const hostname = getApiHostname();
    return hostname.split(".").slice(-MAIN_DOMAIN_PARTS_LENGTH).join(".");
};

export function isTenantSubdomain(): Boolean {
    // App domains are:
    // dev.talking-head.io, qa.talking-head.io, app.talking-head.io, they are always 3 parts
    // Tenant subdomains are like: abc.dev.talking-head.io, abc.qa.talking-head.io, abc.app.talking-head.io, they are at least 4 parts
    // On localhost the API will use some of domains above. This will not work right now for local backend.
    const hostname = getApiHostname();
    return hostname.split(".").length > MAIN_DOMAIN_PARTS_LENGTH;
}

export function isInDevelopmentMode(): Boolean {
    return NODE_ENV === "development";
}

export function getFirstNameAndLastNameInitials(user: any) {
    if (user?.isMta) {
        return "MTA";
    }
    if (user?.firstName && user?.lastName) {
        const first = user.firstName.substr(0, 1);
        const last = user.lastName.substr(0, 1);
        return `${first.toUpperCase()}${last.toUpperCase()}`;
    }

    return user?.username.substr(0, 2) || "";
}
