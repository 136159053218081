export const actions = {
    SET_AUDIO_INPUT_DEVICE_ID: "SET_AUDIO_INPUT_DEVICE_ID",
    SET_AUDIO_OUTPUT_DEVICE_ID: "SET_AUDIO_OUTPUT_DEVICE_ID",
} as const;

enum LOCAL_STORAGE_KEYS {
    OUTPUT_DEVICE = "OUTPUT_DEVICE",
    INPUT_DEVICE = "INPUT_DEVICE",
}

const getOutputDeviceIdFromLocalStorage = (key: LOCAL_STORAGE_KEYS) => {
    return window.localStorage.getItem(key) || null;
};

const setLocalStorageDeviceId = (key: LOCAL_STORAGE_KEYS, value: string) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

export interface AudioDeviceReducerState {
    inputDeviceId: string | null;
    outputDeviceId: string | null;
}

const initialState: AudioDeviceReducerState = {
    inputDeviceId: getOutputDeviceIdFromLocalStorage(LOCAL_STORAGE_KEYS.INPUT_DEVICE),
    outputDeviceId: getOutputDeviceIdFromLocalStorage(LOCAL_STORAGE_KEYS.OUTPUT_DEVICE),
};

const audioDeviceIdReducer = (state: AudioDeviceReducerState = initialState, action: any = {}): AudioDeviceReducerState => {
    switch (action.type) {
        case actions.SET_AUDIO_INPUT_DEVICE_ID: {
            setLocalStorageDeviceId(LOCAL_STORAGE_KEYS.INPUT_DEVICE, action?.payload);
            return { ...state, inputDeviceId: action?.payload };
        }
        case actions.SET_AUDIO_OUTPUT_DEVICE_ID: {
            setLocalStorageDeviceId(LOCAL_STORAGE_KEYS.OUTPUT_DEVICE, action?.payload);
            return { ...state, outputDeviceId: action?.payload };
        }
        default:
            return state;
    }
};

export default audioDeviceIdReducer;
