import { legacy_createStore as createStore } from "redux";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import * as Sentry from "@sentry/react";
import { devToolsEnhancer } from "redux-devtools-extension";
import baseReducer from "./reducers/baseReducer";
import { NODE_ENV } from "./config/app.config";

let storeInstance;
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
if (NODE_ENV === "development") {
    storeInstance = createStore(
        baseReducer,
        devToolsEnhancer({
            serialize: true,
        }),
    );
} else {
    storeInstance = createStore(baseReducer, sentryReduxEnhancer);
}

const store = storeInstance;

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
