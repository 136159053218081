import { MutableRefObject } from "react";
import { AxiosRequestConfig } from "axios";
import { GRID_IDENTIFIERS, GridConfigCache, GridConfigItem } from "../../types";
import {
    apiDeleteGridConfig,
    createGridConfig,
    getGridConfigById,
    getGridConfigListByIdentifier,
    updateGridConfigById,
} from "../services/GridConfigAPIService";

class GridConfigManager {
    private cache: GridConfigCache;

    constructor(container: MutableRefObject<GridConfigCache>) {
        this.cache = container.current;
    }

    async getGridConfigsByIdentifier(identifier: `${GRID_IDENTIFIERS}`, config: AxiosRequestConfig = {}, spinner: boolean = false) {
        const response = await getGridConfigListByIdentifier(identifier, config, spinner);
        const configMap = Object.assign({}, ...response.data.data.map((e) => ({ [e.id]: e })));

        this.cache[identifier].map = { ...this.cache[identifier].map, ...configMap };

        return response;
    }

    async getSpecificGridConfig(identifier: `${GRID_IDENTIFIERS}`, configId: number): Promise<GridConfigItem> {
        if (this.cache[identifier].map[configId]) {
            return Promise.resolve(this.cache[identifier].map[configId]);
        }

        const configRes = await getGridConfigById(identifier, configId);
        const configListData = configRes.data;

        if (configListData.totalElements === 0) {
            return Promise.resolve({
                id: -1,
                name: `Default config ${identifier}`,
                configuration: {
                    pivotMode: false,
                    columnState: [],
                    columnGroupState: [],
                    filterModel: {},
                },
                updated: new Date(),
            } as GridConfigItem);
        }

        const configItem = configListData.data[0];

        this.cache[identifier].map[configId] = configItem;

        return Promise.resolve(this.cache[identifier].map[configId]);
    }

    async saveGridConfig(identifier: `${GRID_IDENTIFIERS}`, currentConfig: GridConfigItem) {
        if (this.cache[identifier].map[currentConfig.id] && currentConfig.id > 0) {
            await updateGridConfigById(currentConfig.id, currentConfig);

            this.cache[identifier].map[currentConfig.id] = currentConfig;
            return currentConfig;
        }

        const newItem = await createGridConfig(identifier, currentConfig);

        this.cache[identifier].map[newItem.id] = { ...currentConfig, id: newItem.id };
        return newItem;
    }

    async deleteGridConfig(identifier: `${GRID_IDENTIFIERS}`, configId: number) {
        await apiDeleteGridConfig(configId);

        if (this.cache[identifier].map[configId]) {
            delete this.cache[identifier].map[configId];
        }
    }
}

export default GridConfigManager;
