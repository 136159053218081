import { useCallback, useState } from "react";

export default function useAsyncError() {
    const setError = useState()[1];
    return useCallback(
        (e) => {
            setError(() => {
                throw e;
            });
        },
        [setError],
    );
}
