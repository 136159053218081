import { Component, PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import "./errorBoundary.css";

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren<object>, State> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return (
                <div className="error-boundary-wrapper">
                    <div className="error-boundary-container">
                        <div>
                            <ReportProblemIcon style={{ fontSize: "5.5rem" }} />
                        </div>
                        <h1 className="error-boundary-heading">
                            <FormattedMessage id="error.page.info-header" defaultMessage="Ooops! Something went wrong." />
                        </h1>
                        <h5 className="error-boundary-heading">
                            <FormattedMessage
                                id="error.page.info-message"
                                defaultMessage="This page didn't load the content correctly. See the JavaScript console for technical details."
                            />
                        </h5>
                    </div>
                </div>
            );
        }
        return children;
    }
}

export default ErrorBoundary;
