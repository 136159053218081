export enum Routes {
    BASE_PATH = "/",
    HOME = "/home",
    ACCOUNT_SETTINGS = "/",
    WELCOME = "/welcome",
    PROFILE = "/profile",
    PERSONAL_USER_EDIT = "",
    PERSONAL_SETTINGS = "/personal-settings",
}

export enum AUTHORIZED_SUB_ROUTE {
    AUTHORIZED = "/authorized",
    ERROR_AUTHORIZED = "error",
}

export enum PublicRoutes {
    PLAYGROUND = "/PLAYGROUND",
    FORM_FILLER = "/form-filler",
    LOGIN = "/login",
    INIT_AUTH = "/init-auth",
    AUTHORIZED = AUTHORIZED_SUB_ROUTE.AUTHORIZED,
    FORGOT_PASSWORD = "/forgot-password",
    UPDATE_PASSWORD = "/update-password",
    REGISTER_USER = "/register/user",
    CONTACT_POINT_VERIFICATION = "/contact-point/verification",
    VERIFY_EMAIL = "/verify-email/:userId/:code",
    ERROR_AUTHORIZED = `${AUTHORIZED_SUB_ROUTE.AUTHORIZED}/${AUTHORIZED_SUB_ROUTE.ERROR_AUTHORIZED}`,
    SET_UP_NEW_ORGANIZATION = "/setup-new-organization",
}

export enum StaticRoutes {
    FORBIDDEN = "/forbidden",
    NOT_FOUND = "/not-found",
}

export enum Files {
    JPG = "image/jpeg,image/x-citrix-jpeg,image/pjpeg",
    PNG = "image/png,image/x-citrix-png,image/x-png",
    BMP = "image/bmp",
    ANY_IMG = "image/*",
    ICO = "image/x-icon",
    XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    PDF = "application/pdf",
    SVG = "image/svg+xml",
    DOC = "application/msword",
    DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    TEXT = "text/plain",
    JSON = "application/json",
}

export enum ImageExtensionTypes {
    PNG = ".png",
    JPG = ".jpg",
    JPEG = ".jpeg",
    SVG = ".svg",
    BMP = ".bmp",
    ICO = ".ico",
    WEBP = ".webp",
    AVF1 = ".avf1",
    JPE = ".jpe",
    PJPG = ".pjpg",
    JFIF = ".jfif",
    JFIFTBNL = ".jfif-tbnl",
    JIF = ".jif",
    JFI = ".jfi",
    GIF = ".gif",
}
export enum ApplicationExtensionTypes {
    PDF = ".pdf",
    XHTML = ".xhtml",
    XLS = ".xls",
    XLSX = ".xlsx",
    XML = ".xml",
    XUL = ".xul",
    EPUB = ".epub",
    DOCX = ".docx",
    ZIP = ".zip",
    SEVEN7Z = ".7z",
    JSON = ".json",
    CSV = ".csv",
}

export enum AudioExtensionTypes {
    WAV = ".wav",
    WEBA = ".weba",
    M2A = ".m2a",
    M3A = ".m3a",
    MP2 = ".mp2",
    MP2A = ".mp2a",
    MP3 = ".mp3",
    MPGA = ".mpga",
}

export enum VideoExtensionTypes {
    WEBM = ".webm",
    MP4 = ".mp4",
    M1V = ".m1v",
    M2V = ".m2v",
    MPA = ".mpa",
    MPE = ".mpe",
    MPG = ".mpg",
}

export enum TextExtensionTypes {
    CONF = ".conf",
    DEF = ".def",
    DIFF = ".diff",
    IN = ".in",
    KSH = ".ksh",
    LIST = ".list",
    LOG = ".log",
    PL = ".pl",
    TEXT = ".text",
    TXT = ".txt",
    SNP = ".snp",
}

export enum MSWordExtensionTypes {
    DOC = ".doc",
    DOT = ".dot",
    WIZ = ".wiz",
}
export enum PowerPointExtensionTypes {
    POT = ".pot",
    PPA = ".ppa",
    PPS = ".pps",
    PPT = ".ppt",
    PWZ = ".pwz",
    PPTX = ".pptx",
}

// ** This type can be used in suitable case for now its not used any where **
export interface CommonSupportedWebFiles {
    "application/pdf": ApplicationExtensionTypes[];
    "application/x-pdf": ApplicationExtensionTypes[];
    "application/x-bzpdf": ApplicationExtensionTypes[];
    "application/x-gzpdf": ApplicationExtensionTypes[];
    "applications/vnd.pdf": ApplicationExtensionTypes[];
    "application/acrobat": ApplicationExtensionTypes[];
    "application/x-google-chrome-pdf": ApplicationExtensionTypes[];
    "text/pdf": ApplicationExtensionTypes[];
    "text/x-pdf": ApplicationExtensionTypes[];
    "application/xhtml+xml": ApplicationExtensionTypes[];
    "application/excel": ApplicationExtensionTypes[];
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ApplicationExtensionTypes[];
    "application/xml": ApplicationExtensionTypes[];
    "application/vnd.mozilla.xul+xml": ApplicationExtensionTypes[];
    "application/epub+zip": ApplicationExtensionTypes[];
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ApplicationExtensionTypes[];
    "application/zip": ApplicationExtensionTypes[];
    "application/x-7z-compressed": ApplicationExtensionTypes[];
    "audio/wav": AudioExtensionTypes[];
    "audio/weba": AudioExtensionTypes[];
    "audio/mpeg": AudioExtensionTypes[];
    "video/webm": VideoExtensionTypes[];
    "video/mp4": VideoExtensionTypes[];
    "video/mpeg": VideoExtensionTypes[];
    "text/plain": TextExtensionTypes[];
    "application/msword": MSWordExtensionTypes[];
    "application/vnd.ms-powerpoint": PowerPointExtensionTypes[];
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": PowerPointExtensionTypes[];
    "image/jpeg": ImageExtensionTypes[];
    "image/x-citrix-jpeg": ImageExtensionTypes[];
    "image/pjpeg": ImageExtensionTypes[];
    "image/png": ImageExtensionTypes[];
    "image/x-citrix-png": ImageExtensionTypes[];
    "image/bmp": ImageExtensionTypes[];
    "image/x-icon": ImageExtensionTypes[];
}

export interface AcceptFormat {
    [x: string]: ImageExtensionTypes[];
}

export enum UserRoles {
    Staff = "Staff",
    Employee = "Employee",
    Manager = "Manager",
    SA = "System Admin", // 'System Administrator'
    CS = "Customer Support", // 'Customer Support'
}

export enum TenantCapabilities {}

export enum InputTypes {
    SELECT,
    TEXT,
    DATE,
    CHECKBOX,
}

export enum MediaQueries {
    TABLET_LANDSCAPE = "(min-width: 768px) and (max-width: 1024px)",
    TABLET = "(min-width: 480px) and (max-width: 768px)",
    PHONE = "(max-width: 480px)",
}

export enum GoogleAddressCountries {
    UNITED_STATES_OF_AMERICA = "United States",
    CANADA = "Canada",
    AUSTRALIA = "Australia",
}

export enum AppAddressCountries {
    UNITED_STATES_OF_AMERICA = "United States",
    CANADA = "Canada",
    AUSTRALIA = "Australia",
}

export enum OrganizationContactTypes {
    PRIMARY = "Primary",
    FINANCE = "Finance",
    OPERATIONS_AND_SUPPORT = "Operations and Support",
    TECHNICAL = "Technical",
}

export enum AccountTab {
    PERSONAL_USER = 0,
}

export enum HTMLTemplateTypes {
    PUBLISHED = "published",
    WORKING = "working",
    COMPILED = "compiled",
    SET_DEFAULT = "setDefault",
}

export enum AccountSettingsTabs {
    PERSONAL_ACCOUNT,
    INDIVIDUAL_PROVIDER_REGISTRATION,
    MY_ORGANIZATIONS,
}
export enum PersonalAccountSettingsTabs {
    PERSONAL_ACCOUNT,
}

export enum TargetAction {
    Default = "default",
    Next = "next",
    SaveAndExit = "saveAndExit",
    Discard = "discard",
    SaveAndStay = "saveAndStay",
    DiscardAndStay = "discardAndStay",
    OldFormErrorAndStay = "oldFormErrorAndStay",
    ExitScreen = "exitScreen",
}

export enum navigateAction {
    NEXT = "next",
    EXIT = "exit",
    INPLACE = "inplace",
    BLOCKER = "blocker",
}

export enum LOGIN_TYPE {
    GOOGLE = "google-idp",
    MICROSOFT = "azure-dev",
}

export enum MediaDeviceKind {
    VIDEO_INPUT = "videoinput",
    AUDIO_INPUT = "audioinput",
    AUDIO_OUTPUT = "audiooutput",
}
