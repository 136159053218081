export const actions = {
    SET_PAGE_OVERLAY: "setPageOverlay",
    SET_PAGE_OVERLAY_MESSAGES: "setPageOverlayMessages",
    ADD_PAGE_OVERLAY_MESSAGE: "addPageOverlayMessage",
    SET_PAGE_OVERLAY_COLOR: "setPageOverlayColor",
    RESET_PAGE_OVERLAY_STATE: "resetPageOverlayState",
} as const;

export const defaultPageOverlayColor = "#fff";

export interface PageOverlayReducerState {
    pageOverlay: boolean;
    pageOverlayMessages: string[];
    pageOverlayColor: string;
}

const initialState: PageOverlayReducerState = {
    pageOverlay: false,
    pageOverlayMessages: [],
    pageOverlayColor: defaultPageOverlayColor,
};

type args = { type?: string; [key: string]: any };

// eslint-disable-next-line default-param-last
const PageOverlayReducer = (state: PageOverlayReducerState = initialState, { type, ...rest }: args) => {
    switch (type) {
        case actions.SET_PAGE_OVERLAY: {
            return { ...state, ...rest };
        }
        case actions.SET_PAGE_OVERLAY_MESSAGES: {
            return { ...state, ...rest };
        }
        case actions.ADD_PAGE_OVERLAY_MESSAGE: {
            return { ...state, pageOverlayMessages: [...state.pageOverlayMessages, rest.message] };
        }
        case actions.SET_PAGE_OVERLAY_COLOR: {
            return { ...state, ...rest };
        }
        case actions.RESET_PAGE_OVERLAY_STATE: {
            return { ...state, pageOverlay: false, pageOverlayMessages: [], pageOverlayColor: defaultPageOverlayColor };
        }
        default:
            return state;
    }
};
export default PageOverlayReducer;
