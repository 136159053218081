import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAxiosInstances } from "../../../../utils/AppUtils";
import { CLIENT_ID, OAUTH_BASE_URL } from "../../../app.config";
import AxiosActions from "../../AxiosActions";
import { getRefreshToken, objectToEncodedRequestBody } from "../../../../utils/AuthUtils/AuthUtils";

export const getContextAuthorizationCode = (config: AxiosRequestConfig = {}, spinner = true) => {
    return AxiosActions.get(getAxiosInstances().V2, "/organization/auth-code", config, spinner);
};
export const getOauth2Authorize = (queryParams: object) => {
    let params: string = "?";
    Object.keys(queryParams).forEach((key: string, index: number) => {
        params += `${index > 0 ? "&" : ""}${key}=${queryParams[key]}`;
    });

    window.location.href = `${OAUTH_BASE_URL}auth/oauth2/authorize${params}`;
};
export const enableMFA = (): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/mfa/enable`, null, {}, true);
};

export const disableMFA = (): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/mfa/disable`, null, {}, true);
};

export const validateAndEnableTotp = (code: string, config?: any): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/mfa/validate?code=${code}`, null, { ...config }, true);
};

export const enableTotp = (): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/mfa/enable/totp`, null, {}, true);
};

export const disableTotp = (): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/mfa/disable/totp`, null, {}, true);
};
export const getContactPoints = (config: any): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `mfa/config`, { withCredentials: false, ...config }, true);
};

export const sendCodeUsingContactPoint = (config: any): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `mfa/send-code`, null, { withCredentials: false, ...config }, true);
};
export const refreshAccessToken = async (): Promise<AxiosResponse> => {
    // curl --location --request POST 'https://test.talking-head.io/auth/oauth2/token' \
    //         --header 'Content-Type: application/x-www-form-urlencoded' \
    //         --data-urlencode 'grant_type=refresh_token' \
    //         --data-urlencode 'refresh_token=9p20TitPn2NOHB_GE3u8VKO2Yr18PbrL87XE6DFOaz_gGQ5nEYCc_VGVA4hlCmkKDNFKrQvLovG7mPmc5CCJtByUCbRAkiv3Kb38kG86Eu-ORsUcnvT2k9RKOOmgFZPF' \
    //         --data-urlencode 'client_id=public-client'

    //         leaving data here since we do not have a swagger for this api

    const details = {
        grant_type: "refresh_token",
        refresh_token: getRefreshToken(),
        client_id: `${CLIENT_ID}`,
    };

    return AxiosActions.post(
        getAxiosInstances().AUTH,
        "oauth2/token",
        objectToEncodedRequestBody(details),
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        false,
    );
};

export const getOauth2Token = (data, config): Promise<AxiosResponse> => {
    return AxiosActions.post(
        getAxiosInstances().AUTH,
        "oauth2/token",
        objectToEncodedRequestBody(data),
        {
            ...config,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        false,
    );
};
export const forgotPassword = (dto: any): Promise<AxiosResponse> => {
    return AxiosActions.post(getAxiosInstances().V2, `/public/reset-password`, dto, { withCredentials: false }, true);
};
