import { combineReducers } from "redux";
import spinnerReducer, { SpinnerReducerState } from "./spinnerReducer";
import userReducer, { UserReducerState } from "./userReducer";
import uiReducer, { UiReducerState } from "./uiReducer";
import pageOverlayReducer, { PageOverlayReducerState } from "./pageOverlayReducer";
import LocalImageFilesReducer, { LocalImageFilesState } from "./localImageFilesReducer";
import audioDeviceIdReducer, { AudioDeviceReducerState } from "./audioDeviceIdReducer";

export interface BaseReducerState {
    spinnerReducer: SpinnerReducerState;
    userReducer: UserReducerState;
    uiReducer: UiReducerState;
    localImageFiles: LocalImageFilesState;
    pageOverlayReducer: PageOverlayReducerState;
    audioDeviceIdReducer: AudioDeviceReducerState;
}

export default combineReducers<BaseReducerState>({
    spinnerReducer,
    userReducer,
    uiReducer,
    localImageFiles: LocalImageFilesReducer,
    pageOverlayReducer,
    audioDeviceIdReducer,
});
