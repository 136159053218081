import { GridActiveConfigs, GridConfigCache } from "../types";

export const defaultCache: GridConfigCache = {
    test: { map: {}, activeId: undefined },
    systemSupport: { map: {}, activeId: undefined },
    apiKeys: { map: {}, activeId: undefined },
    accounts: { map: {}, activeId: undefined },
};

export const initialActiveConfigs: Partial<GridActiveConfigs> = {
    test: undefined,
    systemSupport: undefined,
    apiKeys: undefined,
    accounts: undefined,
};
