import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useTheme } from "@mui/material";
import { useTypedSelector } from "../../../store";
import "react-circular-progressbar/dist/styles.css";
import "./circularProgressSpinner.css";

const CircularProgressSpinner = () => {
    const progressSpinner = useTypedSelector((state) => state.spinnerReducer.progressSpinner);
    const theme: any = useTheme();

    return progressSpinner.show ? (
        <div className="showActionSpinner" key="spinner">
            <div style={{ width: 150, margin: "auto" }}>
                <CircularProgressbar
                    value={progressSpinner.progress}
                    text={`${progressSpinner.progress}%`}
                    strokeWidth={7}
                    styles={buildStyles({
                        textSize: "16px",
                        // Colors
                        pathColor: theme.palette.primary.main,
                        textColor: theme.palette.primary.main,
                        trailColor: "rgba(0,0,0,0.59)",
                        backgroundColor: theme.palette.primary.main,
                    })}
                />
            </div>
        </div>
    ) : null;
};

export default CircularProgressSpinner;
