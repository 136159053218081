/* eslint-disable no-underscore-dangle */

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import store from "../../store";
import { toastError } from "../../utils/AppUtils";

let runningRequests: number = 0;

let stopSpinnerTimer: ReturnType<typeof setTimeout> | null = null;

const removeTimer = () => {
    if (stopSpinnerTimer !== null) {
        clearTimeout(stopSpinnerTimer);
    }
};

const addSpinner = (spinner: boolean) => {
    if (spinner) {
        runningRequests += 1;
        removeTimer();
        store.dispatch({ type: "set", showSpinner: true });
    }
};

export const _sysShowError = (error: any, errorPopup?: boolean) => {
    if (error.response?.data?.message) {
        if (errorPopup) {
            // for popup error handlers
        } else {
            toastError(error.response.data.message);
        }
    } else if (error.response?.data?.error_description) {
        if (errorPopup) {
            // for popup error handlers
        } else {
            toastError(error.response.data.error_description);
        }
    } else if (errorPopup) {
        // for popup error handlers
    } else if (error.message === "canceled") {
        throw error;
    } else {
        toastError("Something went wrong");
    }
    throw error;
};

const errorHandler = (error: any, errorPopup?: boolean) => {
    _sysShowError(error, errorPopup);
};

const hideSpinner = (spinner: boolean) => {
    if (spinner) {
        runningRequests -= 1;
        if (runningRequests <= 0) {
            stopSpinnerTimer = setTimeout(() => {
                store.dispatch({ type: "set", progressSpinner: { show: false, progress: 0 }, showSpinner: false });
            }, 1000);
        }
    }
};

const post = async (
    axiosInstance: AxiosInstance,
    url: string,
    data: any,
    config: AxiosRequestConfig,
    spinner: boolean,
    hideErrors?: boolean,
    errorPopup?: boolean,
): Promise<any> => {
    // if (spinner) {
    //     AxiosActions.runningRequests += 1;
    //     AxiosActions.removeTimer()
    //     store.dispatch({ type: "set", showSpinner: true });
    // }
    addSpinner(spinner);
    try {
        const response = await axiosInstance.post(url, data, config);
        hideSpinner(spinner);
        return response;
    } catch (e) {
        hideSpinner(spinner);
        if (!hideErrors) {
            errorHandler(e, errorPopup);
        }
        return undefined;
    }
};
const postErrorsAreThrown = async (axiosInstance: AxiosInstance, url: string, data: any, config: AxiosRequestConfig, spinner: boolean): Promise<any> => {
    try {
        addSpinner(spinner);
        const response = await axiosInstance.post(url, data, config);
        hideSpinner(spinner);
        return response;
    } catch (error) {
        hideSpinner(spinner);
        throw error;
    }
};

const get = async (
    axiosInstance: AxiosInstance,
    url: string,
    config: AxiosRequestConfig,
    spinner: boolean,
    hideErrors?: boolean,
    errorPopup?: boolean,
): Promise<any> => {
    // if (spinner) {
    //     AxiosActions.runningRequests += 1;
    //     store.dispatch({ type: "set", showSpinner: true });
    // }
    addSpinner(spinner);

    try {
        const response = await axiosInstance.get(url, config);
        hideSpinner(spinner);
        return response;
    } catch (e) {
        hideSpinner(spinner);
        if (!hideErrors) {
            errorHandler(e, errorPopup);
        }
        return undefined;
    }
};

export const requestWithProgress = async (
    axiosInstance: AxiosInstance,
    method: string,
    url: string,
    data: any,
    config: AxiosRequestConfig,
    spinner: boolean,
    hideErrors?: boolean,
    // errorPopup?: boolean, // ! it is not used however existed
): Promise<any> => {
    // if (spinner) {
    //     AxiosActions.runningRequests += 1;
    //     store.dispatch({ type: "set", progressSpinner: { show: true, progress: 0 } });
    // }
    addSpinner(spinner);

    try {
        let response;
        switch (method) {
            case "get":
                response = await axiosInstance.get(url, {
                    ...config,
                    onDownloadProgress(progressEvent) {
                        if (progressEvent.event?.lengthComputable) {
                            store.dispatch({
                                type: "set",
                                progressSpinner: {
                                    show: spinner,
                                    progress: Math.round(progressEvent.progress! * 100),
                                },
                            });
                        }
                    },
                });
                break;
            case "post":
                response = await axiosInstance.post(url, data, {
                    ...config,
                    onUploadProgress(progressEvent) {
                        if (progressEvent.event.lengthComputable) {
                            store.dispatch({
                                type: "set",
                                progressSpinner: {
                                    show: spinner,
                                    progress: Math.round(progressEvent.progress! * 100),
                                },
                            });
                        }
                    },
                });
                break;
            default:
                break;
        }

        hideSpinner(spinner);
        return response;
    } catch (e) {
        hideSpinner(spinner);
        if (!hideErrors) {
            // errorHandler(e, errorPopup);
            throw e;
        }
        return undefined;
    }
};

const put = async (
    axiosInstance: AxiosInstance,
    url: string,
    data: any,
    config: AxiosRequestConfig,
    spinner: boolean,
    hideErrors?: boolean,
    errorPopup?: boolean,
): Promise<any> => {
    // if (spinner) {
    //     AxiosActions.runningRequests += 1;
    //     store.dispatch({ type: "set", showSpinner: true });
    // }
    addSpinner(spinner);

    try {
        const response = await axiosInstance.put(url, data, config);
        hideSpinner(spinner);
        return response;
    } catch (e) {
        hideSpinner(spinner);
        if (!hideErrors) {
            errorHandler(e, errorPopup);
            // throw e;
        }
        return undefined;
    }
};

// currently it is not used
// const patch = async (
//     axiosInstance: AxiosInstance,
//     url: string,
//     data: any,
//     config: AxiosRequestConfig,
//     spinner: boolean,
//     hideErrors?: boolean,
//     errorPopup?: boolean,
// ): Promise<any> => {
//     // if (spinner) {
//     //     AxiosActions.runningRequests += 1;
//     //     store.dispatch({ type: "set", showSpinner: true });
//     // }
//     addSpinner(spinner);

//     try {
//         const response = await axiosInstance.patch(url, data, config);
//         hideSpinner(spinner);
//         return response;
//     } catch (e) {
//         hideSpinner(spinner);
//         if (!hideErrors) {
//             errorHandler(e, errorPopup);
//         }
//         return undefined;
//     }
// };

const deleteCall = async (
    axiosInstance: AxiosInstance,
    url: string,
    config: AxiosRequestConfig,
    spinner: boolean,
    hideErrors?: boolean,
    errorPopup?: boolean,
): Promise<any> => {
    // if (spinner) {
    //     AxiosActions.runningRequests += 1;
    //     store.dispatch({ type: "set", showSpinner: true });
    // }
    addSpinner(spinner);

    try {
        const response = await axiosInstance.delete(url, config);
        hideSpinner(spinner);
        return response;
    } catch (e) {
        hideSpinner(spinner);
        if (!hideErrors) {
            errorHandler(e, errorPopup);
        }
        return undefined;
    }
};

const all = async <T>(values: (T | Promise<T>)[], spinner: boolean, hideErrors?: boolean, errorPopup?: boolean): Promise<any> => {
    // if (spinner) {
    //     AxiosActions.runningRequests += 1;
    //     store.dispatch({ type: "set", showSpinner: true });
    // }
    addSpinner(spinner);

    try {
        const response = await axios.all(values);
        hideSpinner(spinner);
        return response;
    } catch (e) {
        hideSpinner(spinner);
        if (!hideErrors) {
            errorHandler(e, errorPopup);
        }
        return undefined;
    }
};

const AxiosActions = {
    get,
    post,
    postErrorsAreThrown,
    delete: deleteCall,
    put,
    all,
};

export default AxiosActions;
