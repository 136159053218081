import * as React from "react";
import { BO_CORE_DEFAULT_NUMBER_VALUE } from "../utils/AppUtils";

export const actions = {
    SET_GOOGLE_PLACES_API_KEY: "setGooglePlacesApiKey",
    SET_PUBLIC_TENANT_DATA: "setPublicTenantData",
    THEME_PROPS: "themeProps",
    ACTIVE_PAGE_NAME: "activePageName",
    ACTIVE_UI_TAB_RESOURCE_ID: "activeUiTabResourceId",
    FORCE_REFRESH_PRO_GRID: "FORCE_REFRESH_PRO_GRID",
    NEW_DATA_FETCHED_PRO_GRID: "NEW_DATA_FETCHED_PRO_GRID",
    SHOW_BLOCKING_MODAL: "SHOW_BLOCKING_MODAL",
    FORCE_REFRESH_MYWORKFLOWS: "FORCE_REFRESH_MYWORKFLOWS",
    SET_TENANT_DARK_TITLE_ICON_URL: "setTenantDarkTitleIconUrl",
    SET_TENANT_DARK_LOGO_URL: "setTenantDarkLogoUrl",
    SET_TENANT_TITLE_ICON_URL: "setTenantTitleIconUrl",
    SHOW_HEADER: "showHeader",
    MOBILE_OPEN: "mobileOpen",
    TENANT_LOGO_URL: "tenantLogoUrl",
    SHOW_SIDE_BAR: "showSidebar",
    SET_SUBDOMAIN: "setSubdomain",
} as const;

interface IThemeProps {
    data: any;
    appBackgroundStyle: React.CSSProperties;
    publicUrlLogo: string;
    publicBackgroundStyle: React.CSSProperties;
    messageBackground: string;
}
export interface UiReducerState {
    showShopButton: boolean;
    showBuildsButton: boolean;
    isProfileModalOpen: boolean;
    // uploadedImagesForPreview: any;
    themeProps: IThemeProps;
    tenantLogoUrl: string | null;
    tenantTitleIconUrl: string | null;
    tenantDarkLogoUrl: string | null;
    tenantDarkTitleIconUrl: string | null;
    showSidebar: boolean;
    mobileOpen: boolean;
    showHeader: boolean;
    activePageName: string;
    tenantId: number;
    googlePlacesApiKey: string;
    appVersion?: any;
    refreshGrid?: boolean;
    newGridData?: any;
    activeUiTabResourceId?: string;
    showBlockingModal: boolean;
    publicTenantData: any;
    refreshMyWorkflowList?: boolean;
    subdomain: string;
}

const initialState: UiReducerState = {
    showShopButton: false,
    showBuildsButton: false,
    isProfileModalOpen: false,
    showSidebar: true,
    mobileOpen: false,
    showHeader: false,
    // uploadedImagesForPreview: [],
    themeProps: {
        data: {},
        appBackgroundStyle: {
            backgroundImage: "",
            backgroundColor: "black",
        },
        publicUrlLogo: "",
        publicBackgroundStyle: {
            backgroundImage: "",
            backgroundColor: "black",
        },
        messageBackground: "",
    },
    tenantLogoUrl: null,
    tenantTitleIconUrl: null,
    tenantDarkLogoUrl: null,
    tenantDarkTitleIconUrl: null,
    activePageName: "",
    tenantId: BO_CORE_DEFAULT_NUMBER_VALUE,
    googlePlacesApiKey: "",
    activeUiTabResourceId: "",
    showBlockingModal: false,
    publicTenantData: {},
    refreshMyWorkflowList: true,
    subdomain: "",
};

type args = { type?: string; [key: string]: any };

// eslint-disable-next-line default-param-last
const UiReducer = (state: UiReducerState = initialState, { type, ...rest }: args) => {
    switch (type) {
        case "showShopButton":
            return { ...state, ...rest };
        case "showBuildsButton":
            return { ...state, ...rest };
        case "isProfileModalOpen":
            return { ...state, ...rest };
        // case 'uploadedImagesForPreview':
        // return { ...state, ...rest }
        case actions.SHOW_SIDE_BAR:
            return { ...state, ...rest };
        case actions.MOBILE_OPEN:
            return { ...state, ...rest };
        case actions.SHOW_HEADER:
            return { ...state, ...rest };
        case actions.THEME_PROPS:
            return { ...state, ...rest };
        case actions.ACTIVE_PAGE_NAME:
            return { ...state, ...rest };
        case actions.ACTIVE_UI_TAB_RESOURCE_ID:
            return { ...state, ...rest };
        case actions.SET_PUBLIC_TENANT_DATA:
            return { ...state, ...rest };
        // case "setTenantId":
        //     return { ...state, ...rest };
        case actions.SET_GOOGLE_PLACES_API_KEY:
            return { ...state, ...rest };
        case "SET_APP_VERSION":
            return { ...state, ...rest };
        case actions.FORCE_REFRESH_PRO_GRID:
            return { ...state, ...rest };
        case actions.NEW_DATA_FETCHED_PRO_GRID:
            return { ...state, ...rest };
        case actions.SHOW_BLOCKING_MODAL:
            return { ...state, ...rest };
        case actions.TENANT_LOGO_URL:
            return { ...state, ...rest };
        case actions.SET_TENANT_TITLE_ICON_URL:
            return { ...state, ...rest };
        case actions.SET_TENANT_DARK_LOGO_URL:
            return { ...state, ...rest };
        case actions.SET_TENANT_DARK_TITLE_ICON_URL:
            return { ...state, ...rest };
        case actions.SET_SUBDOMAIN:
            return { ...state, ...rest };
        case actions.FORCE_REFRESH_MYWORKFLOWS:
            return { ...state, refreshMyWorkflowList: !state.refreshMyWorkflowList };
        default:
            return state;
    }
};
export default UiReducer;
