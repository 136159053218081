import { useEffect, useState } from "react";

function useAbortApi() {
    const [controller, setController] = useState(new AbortController());

    useEffect(() => {
        if (controller.signal.aborted) {
            setController(new AbortController());
        }

        return () => {
            controller.abort();
        };
    }, []);

    return { signal: controller.signal };
}

export default useAbortApi;
